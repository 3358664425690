<template>
    <div class="thank-you-container">
      <h1 class="thank-you-title">{{translations.sixth_step.title}}</h1>
      <p class="thank-you-message">
        {{translations.sixth_step.description}}
      </p>
    </div>
</template>

<script>
  export default {
    inject: ['translations'],
    
  }
  
</script>
  
<style scoped>
  .thank-you-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background-color: linear-gradient(#121213, #121213), linear-gradient(#121213 50%, rgba(18, 18, 19, 0.6) 30%, rgba(18, 18, 19, 0)), linear-gradient(90deg, var(--seventh), var(--sixth), var(--fifth), var(--fourth), var(--tertiary), var(--secondary), var(--primary)); /* Fondo oscuro */
    color: #F3F3F3; /* Texto en blanco */
    border-radius: 10px;
  }
  
  .thank-you-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--dark__to__gray-lightest);

  }
  
  .thank-you-message {
    font-size: 18px;
    color: var(--dark__to__gray-lightest);
  }
  </style>
  