<template>
    <form v-auto-animate>
        <component ref="stepComponent"
            :is="currentStepComponent"
            v-model="submission"
            :errors="errors"
        ></component>

        <div class="d-flex mt-4 align-items-center"
            v-if="numberToOrdinal(currentStepNumber + 1)"
            :class="{
                'justify-content-between': currentStepNumber > 1,
                'justify-content-end': currentStepNumber === 1,
            }">

            <a class="back-btn" @click.prevent="goBack" v-if="currentStepNumber > 1">{{translations.back_button}}</a>

            <button class="main-button align-items-center" @click.prevent="nextStep" :disabled="isBusy">
                <div v-if="isBusy" class="spinner-border spinner-border-sm mr-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                {{ numberToOrdinal(currentStepNumber + 2) ? translations.next_button : translations.submit_button }}
            </button>
        </div>
    </form>
</template>

<script>
import FirstStep from './FirstStep.vue';
import SecondStep from './SecondStep.vue';
import ThirdStep from './ThirdStep.vue';
import FourthStep from './FourthStep.vue';
import FifthStep from './FifthStep.vue';
import SixthStep from './SixthStep.vue';

import useVuelidate from "@vuelidate/core";
import { required, email, helpers, minLength } from "@vuelidate/validators";

export default {
    inject: ['translations', 'axios', 'locale', '$cookies'],

    components: { FirstStep, SecondStep, ThirdStep, FourthStep, FifthStep, SixthStep },

    setup() {
        return { v$: useVuelidate({ $scope: 'new-submission' }) };
    },

    data: () => ({
        submission: { services: [] },
        currentStepNumber: 1,
        isBusy: false
    }),

    computed: {
        currentStepComponent() {
            return `${this.numberToOrdinal(this.currentStepNumber)}Step`;
        },

        errors() {
            return this.v$.submission
        },

        stepValidations() {
            let validations = {};

            if (this.currentStepNumber === 1) {
                validations = {
                    services: {
                        minLength: helpers.withMessage(this.translations.validations.min_length.replaceAll('[number]', 1), minLength(1)),
                        required: helpers.withMessage(this.translations.validations.required, required)
                    }
                }
            } else if (this.currentStepNumber == 2) {
                validations = {
                    first_name: { required: helpers.withMessage(this.translations.validations.required, required) },
                    last_name: { required: helpers.withMessage(this.translations.validations.required, required) },
                }
            } else if (this.currentStepNumber == 3) {
                validations = {
                    email: {
                        required: helpers.withMessage(this.translations.validations.required, required),
                        email: helpers.withMessage(this.translations.validations.email, email),
                    },
                }
            } else if (this.currentStepNumber == 4) {
                validations = { company: { required: helpers.withMessage(this.translations.validations.required, required) } }
            }

            return { submission: validations }
        }
    },

    methods: {
        numberToOrdinal(n) {
            const ordinals = {
                1: "First",
                2: "Second",
                3: "Third",
                4: "Fourth",
                5: "Fifth",
                6: "Sixth",
            };
            
            return ordinals[n];
        },

        nextStep() {
            if (this.numberToOrdinal(this.currentStepNumber + 2)) {
                this.v$.$validate().then(value => {
                    if (value) {
                        this.currentStepNumber ++;
                    }
                })
            } else {
                this.submit()
            }
        },

        goBack() {
            if (this.numberToOrdinal(this.currentStepNumber - 1) && !this.isBusy) {
                this.currentStepNumber --;
            }
        },

        submit() {
            if (this.isBusy) return;
            this.isBusy = true

            this.axios.post('/submissions', {
                data: { ...this.submission, locale: this.locale }
            }).then(r => {
                this.$cookies.set('zensavvy_user_id', r.data.token)
                this.currentStepNumber ++;
            }).catch(() => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: this.translations.server_errors[500]
                })
            }).finally(() => {
                this.isBusy = false
            })
        }

    },

    validations() {
        return this.stepValidations
    },
}
</script>

<style lang="scss" scoped>
.back-btn {
color: var(--dark__to__gray-lightest);
  text-decoration: none;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s;
}

.back-btn:hover {
  color: #ffffff; /* Cambia el color del texto al blanco al hacer hover */
  text-shadow: 0px 0px 5px rgba(168, 179, 228, 0.7);
}
</style>