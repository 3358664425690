<template>
    <p class="p-zensavvy">{{translations.fifth_step.message}}</p>
    <input type="textarea" class="form-control text-center" v-model="submission.message"/>
</template>

<script>
export default {
    inject: ['translations'],

    props: {
        modelValue: { type: Object, required: true }
    },

    computed: {
        submission: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>