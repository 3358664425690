<template>
    <p class="p-zensavvy">{{translations.second_step.title}}</p>

    <p class="p-zensavvy">{{translations.second_step.first_name}}</p>
    <p class="zensavvy-error" v-for="error in errors.first_name.$errors">
      {{ error.$message }}  
    </p>
    <input type="text" class="form-control mb-3 text-center" v-model="submission.first_name"/>

    <p class="p-zensavvy">{{translations.second_step.last_name}}</p>
    <p class="zensavvy-error" v-for="error in errors.last_name.$errors">
      {{ error.$message }}  
    </p>
    <input type="text" class="form-control text-center" v-model="submission.last_name"/>
</template>

<script>
export default {
    inject: ['translations'],

    props: {
        modelValue: { type: Object, required: true },
        errors: Object
    },

    computed: {
        submission: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>