<template>
    <p class="p-zensavvy">{{ translations.fourth_step.company }}</p>
    <p class="zensavvy-error" v-for="error in errors.company.$errors">
      {{ error.$message }}  
    </p>
    <input type="email" class="form-control text-center" v-model="submission.company"/>
</template>

<script>
export default {
    inject: ['translations'],
    props: {
        modelValue: { type: Object, required: true },
        errors: Object
    },

    computed: {
        submission: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>